import "./style.css";
import arrowDown from "./../../assets/images/arrowDownColor.png";
import close from "./../../assets/images/close.png";
import { TopBar } from "../../components/TopBar/TopBar";
import { BottomPurchase } from "../../components/BottomPurchase/BottomPurchase";
import { useParams } from "react-router-dom";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { UserAuth } from "../../context/AuthContext";
import { CallApiBackend } from "../../utils/CallApiBackend";
import * as Msg from "./../../components/ui/Toastify/Toastify";
import { DelayToastTime } from "../../constants/TimeConstant";
import {
  ChiTietDonHang,
  GetAddress,
  SuaDonHang,
} from "../../constants/ApiEndPoint";
import { ValidatePhone } from "../../utils/Validations";
import ItemProductList from "../../components/ItemProductList/ItemProductList";
import { Button, Col, Input, Row, Select, Space, Spin, Typography } from "antd";
import { useImmer } from "use-immer";
import { LoadingCustom } from "../../components/Loading/LoadingCustom";

export function EditOrderScreen() {
  const { order_id } = useParams();
  const { userData, updateUserInfo } = useContext(UserAuth);
  // const hasHamletProvinces = ["68", "91"];

  const inputNameRef = useRef(null);
  const inputPhoneRef = useRef(null);
  const inputProvinceRef = useRef(null);
  const inputDistrictRef = useRef(null);
  const inputWardRef = useRef(null);
  const inputAddressRef = useRef(null);
  const inputNoteRef = useRef(null);

  const [optionAddress, setOptionAddress] = useImmer({
    province: [],
    district: [],
    ward: [],
    hamlet: [],
  });
  const [location, setLocation] = useImmer({
    province: {},
    district: {},
    ward: {},
    hamlet: {},
    address: "",
    fullname: "",
    phone: "",
  });
  const [orderInfo, setOrderInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // gọi api lấy địa chỉ, type = 1,2,3 - tỉnh - quận - xã
  const CallApiOptionAddress = useCallback(async (input, typeAddress) => {
    const keyAddress = {
      1: "province",
      2: "district",
      3: "ward",
    };
    const result = await CallApiBackend(input, GetAddress, "GET");
    if (result.data.status === 1) {
      const convert = ConvertOptions(result.data.data, typeAddress);
      setOptionAddress((item) => ({
        ...item,
        [keyAddress[typeAddress]]: convert,
      }));
      return convert;
    }
    return [];
  }, []);

  const convertValueDefault = (item) => {
    if (!item) return null;
    return { value: item.code, label: item.name };
  };

  const getDataLocation = async (type) => {
    const keyAddress = {
      1: "province",
      2: "district",
      3: "ward",
      4: "hamlet",
    };
    if (type > 1) {
      if (!location[keyAddress[type - 1]]) {
        return false;
      }
    }

    if (optionAddress[keyAddress[type]]?.length) {
      return;
    }
    const result = await CallApiBackend(
      {
        type: type,
        parent_code: location[keyAddress[type - 1]]?.code,
      },
      GetAddress
    );
    if (result.data.status === 1) {
      const convert = ConvertOptions(result.data.data);
      setOptionAddress((draft) => {
        draft[keyAddress[type]] = convert;
      });
    }
  };

  const handleChangeLocation = async (e, type) => {
    const keyAddress = {
      1: "province",
      2: "district",
      3: "ward",
      4: "hamlet",
    };
    const locationSelect = optionAddress[keyAddress[type]].find(
      (item) => "" + item.value === "" + e
    );

    setOptionAddress((draft) => {
      for (let i = type + 1; i <= 4; i++) {
        draft[keyAddress[i]] = [];
      }
    });
    setLocation((draft) => {
      draft[keyAddress[type]] = {
        code: locationSelect.value,
        name: locationSelect.label,
      };
      for (let i = type + 1; i <= 4; i++) {
        draft[keyAddress[i]] = null;
      }
    });
  };

  // get chi tiet đơn hàng
  useEffect(() => {
    var full_address;
    CallApiBackend({ order_id: order_id }, ChiTietDonHang, "GET")
      .then((result) => {
        setIsLoading(false);
        if (result.data.status === 1) {
          setOrderInfo(result.data.data);
          full_address = result.data.data.full_address;
          for (const key in full_address) {
            if (key === "province" || key === "district" || key === "ward") {
              full_address[key].label = full_address[key].name;
              full_address[key].value = full_address[key].code;
            }
            if (key === "hamlet") {
              let hamlet = {
                label: full_address[key],
                value: full_address[key],
                name: full_address[key],
                code: full_address[key],
              };
              full_address[key] = hamlet;
            }
          }
          setLocation((draft) => {
            draft.province = full_address.province;
            draft.district = full_address.district;
            draft.ward = full_address.ward;
            draft.hamlet = full_address.hamlet;
            draft.address = full_address.address;
            draft.fullname = result.data.data.fullname;
            draft.phone = result.data.data.phone;
            draft.note = result.data.data.note;
          });
          return CallApiOptionAddress({ type: 1 }, 1);
        }
      })
      .then((result1) => {
        if (result1) {
          setOptionAddress((prev) => ({
            ...prev,
            province: result1,
          }));
        }
        return CallApiOptionAddress(
          { type: 2, parent_code: full_address.province.code },
          2
        );
      })
      .then((result2) => {
        if (result2) {
          setOptionAddress((prev) => ({
            ...prev,
            district: result2,
          }));
        }
        return CallApiOptionAddress(
          { type: 3, parent_code: full_address.district.code },
          3
        );
      })
      .then((result3) => {
        if (result3) {
          setOptionAddress((prev) => ({
            ...prev,
            ward: result3,
          }));
        }
      });
  }, [order_id]);

  const ConvertOptions = (list, type) =>
    list.map((item) => ({
      ...item,
      ...{ value: item.code, label: item.name, type: type },
    }));

  const onChangeInput = (element) => {
    if (element.target.name === "phone") {
      const input = element.target;
      input.value = input.value.replace(/\D/g, "");
    }
    setLocation((item) => ({
      ...item,
      [element.target.name]: element.target.value,
    }));
  };

  const onClickEdit = () => {
    if (
      !location.province ||
      !location.district ||
      !location.ward ||
      !location.address
    ) {
      Msg.notifyError(
        "Vui lòng điền đầy đủ thông tin giao hàng!",
        DelayToastTime
      );
    } else if (!location.fullname || !location.phone) {
      Msg.notifyError(
        "Vui lòng điền đầy đủ thông tin người nhận!",
        DelayToastTime
      );
    } else if (ValidatePhone(location.phone).status === false) {
      Msg.notifyError(
        "Vui lòng điền đầy số điện thoại chính xác!",
        DelayToastTime
      );
    } else {
      const thongTinDonHang = {
        order_id: order_id,

        location: {
          province: {
            code: location.province.code,
            name: location.province.name,
          },
          district: {
            code: location.district.code,
            name: location.district.name,
          },

          ward: {
            code: location.ward.code,
            name: location.ward.name,
          },
          address: location.address,
          hamlet: location.hamlet?.name,
        },
        // cif: userData.cif,
        fullname: location.fullname,
        phone: location.phone,
        note: location.note,
        display_price: orderInfo.payment,
      };
      CallApiBackend(thongTinDonHang, SuaDonHang, "POST").then((result) => {
        if (result.data.status === 1) {
          Msg.notifyInfo("Chỉnh sửa đơn hàng thành công", DelayToastTime);
          if (userData?.is_loggedIn) {
            let userInfoNew = userData;
            userInfoNew.address = {
              ...(userInfoNew?.address || {}),
              metadata: {
                ...(userInfoNew?.address?.metadata || {}),
                location: {
                  province: {
                    code: location.province.code,
                    name: location.province.name,
                  },
                  district: {
                    code: location.district.code,
                    name: location.district.name,
                  },

                  ward: {
                    code: location.ward.code,
                    name: location.ward.name,
                  },
                  address: location.address,
                  hamlet: location.hamlet?.name,
                },
                fullname: location.fullname,
                phone: location.phone,
                note: location.note,
              },
            };
            updateUserInfo({ ...userInfoNew });
          }
          setTimeout(() => {
            window.location.href = "/history/detail/" + order_id;
          }, DelayToastTime);
        }
      });
    }
  };
  const removeValueInput = (name) => {
    setLocation((item) => ({ ...item, [name]: "" }));
  };

  const handleFocus = (inputRef, block = "center") => {
    // setTimeout(() => {
    //   if (inputRef.current) {
    //     inputRef.current.scrollIntoView({
    //       behavior: "smooth",
    //       block: block,
    //       inline: "nearest",
    //     });
    //   }
    // }, 10); // Đợi bàn phím xuất hiện
  };

  return (
    <>
      <TopBar goHome={false} title={"Chỉnh sửa đơn hàng"} />
      {isLoading || !orderInfo ? (
        <div className="relative flex flex-col h-screen bg-[#FCF4DE]">
          <LoadingCustom isLoading={isLoading} isFullScreen={true} />
        </div>
      ) : (
        <div className="flex flex-col bg-[#FCF4DE] h-screen">
          <div style={{ marginBottom: "15px" }}>
            <Space direction="vertical" size={15} className="p-3.5">
              <Space direction="vertical" size={10}>
                <Typography.Text className="font-semibold text-base uppercase mb-1 block">
                  Thông tin giỏ hàng
                </Typography.Text>
                <div className="grid grid-cols-1 gap-5 auto-rows-fr">
                  {orderInfo.order_details.map((orderInfoItem, key) => (
                    <ItemProductList
                      item={orderInfoItem}
                      key={key}
                      mode={2}
                      numberIndex={key + 1}
                    />
                  ))}
                </div>
              </Space>
              <Space direction="vertical" size={10}>
                <Typography.Text className="font-semibold text-base uppercase mb-1 block">
                  Thông tin người nhận
                </Typography.Text>
                <Row
                  ref={inputNameRef}
                  className="relative px-3 py-2.5 bg-[#FFFDF7] border border-[#FFE8AA] rounded"
                  align="middle"
                  wrap={false}
                >
                  <Col span={23}>
                    <Typography.Text className="text-sm text-[#C1A047]">
                      Tên người nhận
                    </Typography.Text>
                    <Input
                      bordered={false}
                      rootClassName="px-0"
                      name={"fullname"}
                      id={"fullname"}
                      onChange={onChangeInput}
                      onFocus={() => {
                        handleFocus(inputNameRef);
                      }}
                      value={location?.fullname ?? ""}
                      placeholder={"Nhập họ tên người nhận"}
                    />
                  </Col>
                  <Col
                    onClick={() => removeValueInput("fullname")}
                    span={1}
                    className="flex justify-center opacity-80"
                  >
                    <img src={close} width={19} height={19} alt="" />
                  </Col>
                </Row>
                <Row
                  ref={inputPhoneRef}
                  className="relative px-3 py-2.5 bg-[#FFFDF7] border border-[#FFE8AA] rounded"
                  align="middle"
                  wrap={false}
                >
                  <Col span={23}>
                    <Typography.Text className="text-sm text-[#C1A047]">
                      Số điện thoại
                    </Typography.Text>
                    <Input
                      bordered={false}
                      rootClassName="px-0"
                      name={"phone"}
                      onChange={onChangeInput}
                      onFocus={() => {
                        handleFocus(inputPhoneRef);
                      }}
                      id={"phone"}
                      placeholder={"Nhập số điện thoại người nhận"}
                      value={location?.phone ?? ""}
                    />
                  </Col>
                  <Col
                    onClick={() => removeValueInput("phone")}
                    span={1}
                    className="flex justify-center opacity-80"
                  >
                    <img src={close} width={19} height={19} alt="" />
                  </Col>
                </Row>

                <p className="text-sm text-red-500">
                  {ValidatePhone(location?.phone).msg}{" "}
                </p>
              </Space>
              <Space direction="vertical" size={10} className="custom-select1">
                <Typography.Text className="font-semibold text-base uppercase mb-1 block">
                  Thông tin giao hàng
                </Typography.Text>
                <Row
                  ref={inputProvinceRef}
                  className="relative px-3 py-2.5 bg-[#FFFDF7] border border-[#FFE8AA] rounded"
                  align="middle"
                  wrap={false}
                >
                  <Col span={23}>
                    <Typography.Text className="text-sm text-[#C1A047]">
                      Tỉnh/Thành phố
                    </Typography.Text>
                    <Select
                      notFoundContent={
                        <span className="flex justify-center py-2">
                          <Spin size="small" />
                        </span>
                      }
                      bordered={false}
                      suffixIcon={<></>}
                      className="w-full"
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={optionAddress["province"]}
                      onFocus={() => {
                        getDataLocation(1);
                        handleFocus(inputProvinceRef);
                      }}
                      onChange={(e) => handleChangeLocation(e, 1)}
                      value={convertValueDefault(location?.province)}
                      placeholder="Chọn tỉnh/thành phố"
                    />
                  </Col>
                  <Col span={1} className="flex justify-center opacity-80">
                    <img src={arrowDown} width={12} alt="" />
                  </Col>
                </Row>
                <Row
                  ref={inputDistrictRef}
                  className="relative px-3 py-2.5 bg-[#FFFDF7] border border-[#FFE8AA] rounded"
                  align="middle"
                  wrap={false}
                >
                  <Col span={23}>
                    <Typography.Text className="text-sm text-[#C1A047]">
                      Quận/Huyện
                    </Typography.Text>
                    <Select
                      notFoundContent={
                        <span className="flex justify-center py-2">
                          <Spin size="small" />
                        </span>
                      }
                      bordered={false}
                      suffixIcon={<></>}
                      className="w-full"
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={optionAddress["district"]}
                      value={convertValueDefault(location?.district)}
                      onFocus={() => {
                        getDataLocation(2);
                        handleFocus(inputDistrictRef);
                      }}
                      onChange={(e) => handleChangeLocation(e, 2)}
                      placeholder="Chọn quận/huyện"
                    />
                  </Col>
                  <Col span={1} className="flex justify-center opacity-80">
                    <img src={arrowDown} width={12} alt="" />
                  </Col>
                </Row>
                <Row
                  ref={inputWardRef}
                  className="relative px-3 py-2.5 bg-[#FFFDF7] border border-[#FFE8AA] rounded"
                  align="middle"
                  wrap={false}
                >
                  <Col span={23}>
                    <Typography.Text className="text-sm text-[#C1A047]">
                      Phường/Xã
                    </Typography.Text>
                    <Select
                      notFoundContent={
                        <span className="flex justify-center py-2">
                          <Spin size="small" />
                        </span>
                      }
                      bordered={false}
                      suffixIcon={<></>}
                      className="w-full"
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={optionAddress["ward"]}
                      value={convertValueDefault(location?.ward)}
                      onFocus={() => {
                        getDataLocation(3);
                        handleFocus(inputWardRef);
                      }}
                      onChange={(e) => handleChangeLocation(e, 3)}
                      placeholder="Chọn phường/xã"
                    />
                  </Col>
                  <Col span={1} className="flex justify-center opacity-80">
                    <img src={arrowDown} width={12} alt="" />
                  </Col>
                </Row>
                <Row
                  ref={inputAddressRef}
                  className="relative px-3 py-2.5 bg-[#FFFDF7] border border-[#FFE8AA] rounded"
                  align="middle"
                  wrap={false}
                >
                  <Col span={23}>
                    <Typography.Text className="text-sm text-[#C1A047]">
                      Đường, Số nhà, toà nhà
                    </Typography.Text>
                    <Input
                      bordered={false}
                      rootClassName="px-0"
                      placeholder="Nhập địa chỉ"
                      name={"address"}
                      id={"address"}
                      onChange={onChangeInput}
                      onFocus={() => {
                        handleFocus(inputAddressRef);
                      }}
                      defaultValue={location?.address}
                    />
                  </Col>
                </Row>
                <Row
                  ref={inputNoteRef}
                  className="relative px-3 py-2.5 bg-[#FFFDF7] border border-[#FFE8AA] rounded"
                  align="middle"
                  wrap={false}
                >
                  <Col span={23}>
                    <Typography.Text className="text-sm text-[#C1A047]">
                      Ghi chú
                    </Typography.Text>
                    <Input
                      bordered={false}
                      rootClassName="px-0"
                      placeholder="Thêm ghi chú"
                      name={"note"}
                      id={"note"}
                      onChange={onChangeInput}
                      onFocus={() => {
                        handleFocus(inputNoteRef);
                      }}
                      defaultValue={location?.note}
                    />
                  </Col>
                </Row>
              </Space>
            </Space>
          </div>
          <BottomPurchase paddingX={15} paddingY={20}>
            <div className={"d-flex justify-content-between"}>
              <div className={"total-money"} style={{ margin: "auto 0" }}></div>
              <div
                onClick={onClickEdit}
                className={"buy-now"}
                style={{ width: "100%" }}
              >
                <Button
                  type="primary"
                  className="button"
                  id="fail-button-1"
                  style={{ marginTop: "5px", width: "100%" }}
                >
                  THAY ĐỔI THÔNG TIN
                </Button>
              </div>
            </div>
          </BottomPurchase>
        </div>
      )}
    </>
  );
}
