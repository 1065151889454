import React, { lazy, Suspense, useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Router,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { ProgramRules } from "../pages/ProgramRules/ProgramRules";
import { ScreenPurchaseInformation } from "../pages/Order/OrderScreen";
import { AuthContext } from "../context/AuthContext";
import { ToastContainer } from "react-toastify";
import { EditOrderScreen } from "../pages/Order/EditOrder";
import NotFound from "../pages/NotFound/NotFound";
import { LoadingCustom } from "../components/Loading/LoadingCustom";

const Home = lazy(() => import("./../pages/Home/Home"));
const LDP = lazy(() => import("./../pages/Home/LDP"));
const HomeFlashSale = lazy(() =>
  import("./../pages/HomeFlashSale/HomeFlashSaleScreen")
);
const Detail = lazy(() => import("../pages/ProductDetail/Screen"));
const History = lazy(() => import("./../pages/Histories/ScreenHistory"));
const HistoryDetail = lazy(() =>
  import("./../pages/Histories/ScreenHistoryDetail")
);
const GioHang = lazy(() => import("./../pages/GioHang/GioHang"));
const DanhGiaSanPham = lazy(() =>
  import("./../pages/DanhGiaSanPham/DanhGiaSanPham")
);
const OrderResult = lazy(() => import("../pages/Order/OrderResult"));
const OrderPreview = lazy(() => import("../pages/Order/OrderPreview"));
const ReviewSuccess = lazy(() =>
  import("./../pages/DanhGiaSanPham/DanhGiaSuccess")
);
const ShowReview = lazy(() => import("./../pages/DanhGiaSanPham/ShowReview"));
const EditReview = lazy(() => import("./../pages/DanhGiaSanPham/EditReview"));
const Evaluate = lazy(() => import("../pages/Evaluate/EvaluateDetail"));

function HCVRedirectHandler() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const { pathname, search } = location;

    // Kiểm tra nếu URL chứa /huongcatviet/
    if (pathname.startsWith("/huongcatviet")) {
      const segments = pathname.split("/").filter(Boolean); // Tách các phần trong URL
      if (segments.length === 1) {
        // Trường hợp chỉ là /huongcatviet hoặc /huongcatviet/
        return;
      } else {
        // Trường hợp /huongcatviet/path
        const newPath = `/${segments.slice(1).join("/")}`; // Loại bỏ /huongcatviet
        navigate(newPath + search, { replace: true }); // Redirect về path mới
      }
    }
  }, [location, navigate]);

  return null; // Không render gì
}

export default function RoutesMain() {
  return (
    <BrowserRouter>
      <AuthContext>
          <HCVRedirectHandler />
          <Routes>
            <Route
              exact
              path={"/huongcatviet"}
              element={
                <Suspense fallback={<LoadingCustom />}>
                  <LDP />
                </Suspense>
              }
            />
            <Route
              exact
              path={"/"}
              element={
                <Suspense fallback={<LoadingCustom />}>
                  <LDP />
                </Suspense>
              }
            />
            {/* <Route
            exact
            path={"/home/:supplier_id"}
            element={
              <Suspense fallback={<LoadingCustom />}>
                <Home />
              </Suspense>
            }
          /> */}
            <Route
              exact
              path={"/the-le-chuong-trinh"}
              element={<ProgramRules />}
            />
            <Route
              exact
              path={"/product-detail/:product_id"}
              element={
                <Suspense fallback={<LoadingCustom />}>
                  <Detail />
                </Suspense>
              }
            />
            <>
              <Route
                exact
                path={"/order/payment"}
                element={<ScreenPurchaseInformation />}
              />
              <Route
                exact
                path={"/order/result"}
                element={
                  <Suspense fallback={<LoadingCustom />}>
                    <OrderResult />
                  </Suspense>
                }
              />
              <Route
                exact
                path={"/order/preview"}
                element={
                  <Suspense fallback={<LoadingCustom />}>
                    <OrderPreview />
                  </Suspense>
                }
              />
              {/* <Route
              exact
              path={"/order/:order_id/show-review"}
              element={
                <Suspense fallback={<LoadingCustom />}>
                  <ShowReview />
                </Suspense>
              }
            /> */}
              <Route
                exact
                path={"/order/edit/:order_id"}
                element={
                  <Suspense fallback={<LoadingCustom />}>
                    <EditOrderScreen />
                  </Suspense>
                }
              />
              <Route
                exact
                path={"/gio-hang"}
                element={
                  <Suspense fallback={<LoadingCustom />}>
                    <GioHang />
                  </Suspense>
                }
              />

              {/* <Route
              exact
              path={"/order/review/:order_id"}
              element={
                <Suspense fallback={<LoadingCustom />}>
                  <DanhGiaSanPham />
                </Suspense>
              }
            /> */}

              {/* <Route
              exact
              path={"/order/review-cuccess/:order_id"}
              element={
                <Suspense fallback={<LoadingCustom />}>
                  <ReviewSuccess />
                </Suspense>
              }
            /> */}
              {/* <Route
              exact
              path={"/review/edit/:order_id"}
              element={
                <Suspense fallback={<LoadingCustom />}>
                  <EditReview />
                </Suspense>
              }
            /> */}

              {/* <Route
              exact
              path={"/product/:product_id/evaluate"}
              element={
                <Suspense fallback={<LoadingCustom />}>
                  <Evaluate />
                </Suspense>
              }
            /> */}
              <Route
                exact
                path={"/history"}
                element={
                  <Suspense fallback={<LoadingCustom />}>
                    <History />
                  </Suspense>
                }
              />
              <Route
                exact
                path={"/history/detail/:order_id"}
                element={
                  <Suspense fallback={<LoadingCustom />}>
                    <HistoryDetail />
                  </Suspense>
                }
              />
            </>
            <Route exact path={"*"} element={<NotFound />} />
          </Routes>
      </AuthContext>
      <ToastContainer limit={5} />
    </BrowserRouter>
  );
}
