export const DanhSachSanPhamFlashSale = "/product/list";
export const SanPhamTuongTu = '/product/related';
export const ChiTietSanPham = "/product/detail";
export const LayGioHang = "/cart/detail";
export const ThemGioHang = "/cart/add";
export const XoaGioHang = "/cart/remove";
export const GetAddress = "/shipping/address-list";
export const GetLichSuDatHang = "/order/history";
export const TaoDonHang = "/order/create";
export const KiemTraDonHang = "/order/check";
export const ThanhToanLai = "/order/repay";
export const DatTrangThaiNhacNho = "/user/set-remind";
export const HuyDonHang = "/order/cancel";
export const ChiTietDonHang = "/order/detail";
export const SuaDonHang = "/order/edit";
export const LiDoHuy = "/order/reason-list";
export const DanhSachDanhMucSanPham = "/product/category";
export const DanhSachFlashSale = "/product/flashsale";
export const LayDiaChiCap4 = "/ghtk/address/level4";
export const DanhSachSanPhamSapDienRa = "/product/comming";
export const DanhSachBanner = "/banner/list";
export const DanhSachLichVucNhaCC = "/field/list";
export const DanhSachNCC = "/supplier/list";
export const ChiTietDanhGia = "/review/detail";
export const DanhSachDanhGiaTheoDonHang = "/review/by-order";
export const DanhSachDanhGia = "/review/list";
export const DanhGiaTongQuan = "/review/rate-statistic";
export const DanhSachTheThanhToan = "/user/card-list";
export const DanhSachSanPhamQuanTam = "/product/interest-list";
export const TaoThongTinLienHe = "/product/interest";
export const AUTH_REFRESH_TOKEN = "/auth/refresh";