import { createContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { CallApiBackend } from "../utils/CallApiBackend";
import * as ApiEndPoint from "../constants/ApiEndPoint";
import { DanhSachBanner } from "../constants/ApiEndPoint";

export const UserAuth = createContext({});

export const AuthContext = ({ children }) => {
  const [searchParams] = useSearchParams();
  const [userData, setUserData] = useState(null);
  const [banner, setBanner] = useState([]);
  const [cartData, setCartData] = useState([]);

  //lấy danh sách banner
  useEffect(() => {
    async function getData() {
      try {
        let bannerLocal = window.sessionStorage.getItem("banner_local");
        if (bannerLocal) {
          setBanner(JSON.parse(bannerLocal));
        } else {
          const response = await CallApiBackend({}, DanhSachBanner);
          if (response.data?.status === 1) {
            let result = response.data?.data;
            if (result) {
              setBanner(result);
              window.sessionStorage.setItem(
                "banner_local",
                JSON.stringify(result)
              );
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    }

    getData();
  }, []);

  useEffect(() => {
    const verifyToken = searchParams.get("verifyToken");
    let userLocal = sessionStorage.getItem("user");
    if (verifyToken && !userLocal) {
      CallApiBackend({ verifyToken: verifyToken }, "/user/info", "GET").then(
        (result) => {
          const data = result.data.data;
          if (result.data?.status === 1) {
            updateUserInfo(data);
            CallApiBackend({}, ApiEndPoint.LayGioHang, "GET").then((result) => {
              if (result.data?.status === 1) {
                data.cart_count = {};
                for (let item of result.data.data) {
                  data.cart_count[item.supplier_id] = item.list.length;
                }
                updateUserInfo(data);
              }
            });
          } else {
            setUserData(false);
          }
        }
      );
    } else {
      let userLocal = sessionStorage.getItem("user");
      if (userLocal) {
        setUserData(JSON.parse(userLocal));
      }
    }
  }, [searchParams]);
  const updateUserInfo = (data) => {
    setUserData(data);
    sessionStorage.setItem("user", JSON.stringify(data));
  };

  useEffect(() => {
    let cartLocal = localStorage.getItem("cart");
    if (cartLocal && !userData?.is_loggedIn) {
      setCartData(JSON.parse(cartLocal));
    }
  }, []);

  const updateCartInfo = (data) => {
    setCartData(data);
    localStorage.setItem("cart", JSON.stringify(data));
  };
  return (
    <UserAuth.Provider
      value={{ userData, updateUserInfo, banner, cartData, updateCartInfo }}
    >
      {children}
    </UserAuth.Provider>
  );
};
