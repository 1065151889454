import arrowDown from "./../../assets/images/arrowDownColor.png";
import close from "./../../assets/images/close.png";
import { TopBar } from "../../components/TopBar/TopBar";
import { BottomPurchase } from "../../components/BottomPurchase/BottomPurchase";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { UserAuth } from "../../context/AuthContext";
import { CallApiBackend } from "../../utils/CallApiBackend";
import * as Msg from "./../../components/ui/Toastify/Toastify";
import { formatPrice } from "../../utils/formatPrice";
import { DelayToastTime } from "../../constants/TimeConstant";
import {
  GetAddress,
  KiemTraDonHang,
  TaoDonHang,
} from "../../constants/ApiEndPoint";
import { useImmer } from "use-immer";
import { ValidatePhone } from "../../utils/Validations";
import {
  Button,
  Col,
  Input,
  Radio,
  Row,
  Select,
  Skeleton,
  Space,
  Spin,
  Typography,
} from "antd";
import ItemProductList from "../../components/ItemProductList/ItemProductList";
import React from "react";
import { LoadingCustom } from "../../components/Loading/LoadingCustom";
import { getListCardRequest } from "../../service/orderRequest";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const { Text } = Typography;

export function ScreenPurchaseInformation() {
  const { state } = useLocation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const navigate = useNavigate();
  const { userData, updateUserInfo, cartData, updateCartInfo } =
    useContext(UserAuth);
  const inputNameRef = useRef(null);
  const inputPhoneRef = useRef(null);
  const inputProvinceRef = useRef(null);
  const inputDistrictRef = useRef(null);
  const inputWardRef = useRef(null);
  const inputAddressRef = useRef(null);
  const inputNoteRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [loadingListCard, setLoadingListCard] = useState(false);
  const [formSubmitOrder, setFormSubmitOrder] = useImmer({
    classifies: {},
    location: {},
    fullname: "",
    phone: "",
    note: "",
    is_cart: false,
    display_price: 0,
    payment_type: 0,
    polixy_check: false,
    card_id: "",
  });
  const [listCard, setListCard] = useState(null);
  const [paymentType, setPaymentType] = useState([]);
  const [optionAddress, setOptionAddress] = useImmer({
    province: [],
    district: [],
    ward: [],
    hamlet: [],
  });
  const [orderInfo, setOrderInfo] = useState(null);
  // const hasHamletProvinces = ["68", "91"];

  const [openDieuKhoan, setOpenDieuKhoan] = useState(false);

  const [loadingButtonBuyNow, setLoadingButtonBuyNow] = useState(false);

  const listLocationRender = [
    {
      name: "Tỉnh/Thành phố",
      type: 1,
      key: "province",
      ref: inputProvinceRef,
      placeholder: "Chọn tỉnh/thành phố",
    },
    {
      name: "Quận/Huyện",
      type: 2,
      key: "district",
      ref: inputDistrictRef,
      placeholder: "Chọn quận/huyện",
    },
    {
      name: "Phường/Xã",
      type: 3,
      key: "ward",
      ref: inputWardRef,
      placeholder: "Chọn phường/xã",
    },
    {
      name: "Tên thôn/ấp/xóm/tổ",
      type: 4,
      key: "hamlet",
      check: "isHamlet",
      placeholder: "",
    },
  ];

  // lấy thông tin đơn hàng
  useEffect(() => {
    if (state && !orderInfo) {
      let { classify } = state;

      const classifies = classify.reduce(
        (obj, item) => ({ ...obj, [item.classify_id]: item.qty }),
        {}
      );

      // Kiểm tra đơn hàng
      CallApiBackend(
        {
          classifies: classifies,
          location: {
            province: userData?.address?.province,
            district: userData?.address?.district,
            ward: userData?.address?.ward,
          },
          is_cart: state.isCart,
        },
        KiemTraDonHang,
        "POST"
      ).then(({ data }) => {
        if (data.status === 1) {
          setOrderInfo(data.data);
          //set default order submit
          setFormSubmitOrder((draft) => {
            draft.note =
              userData?.address?.metadata?.note ||
              userData?.address?.note ||
              "";
            draft.location =
              userData?.address?.metadata?.shipment_mode ===
                userData?.current_ship_mode && userData?.current_ship_mode
                ? userData?.address?.metadata || {}
                : {};
            draft.classifies = classifies;
            draft.fullname =
              userData?.address?.metadata?.fullname || userData?.fullname;
            draft.phone = userData?.address?.metadata?.phone || userData?.phone;
            draft.is_cart = state.isCart;
            draft.display_price =
              data.data.sale_price +
              data.data.transport_fee -
              data.data.transport_fee_discount;
            draft.payment_type = data.data.payment_type.find(
              (item) => item.active && !item.card_list
            )?.value;
          });
          //
          //setup các loại thanh toán
          const listPayment = data.data.payment_type
            .filter((item) => item.active)
            .reduce((array, item) => {
              if (item.group === 0 || !item.group) {
                array.push(item);
              } else {
                const indexArray = array.findIndex(
                  (i) => i.key_group === item.group
                );
                if (indexArray >= 0) {
                  array[indexArray].groups.push(item);
                } else {
                  array.push({
                    title: item.title,
                    description: item.description,
                    groups: [item],
                    key_group: item.group,
                  });
                }
              }
              return array;
            }, []);
          setPaymentType(listPayment);
        } else {
          //noti order fail
          Msg.notifyError(
            data.message || "Có lỗi xảy ra. Vui lòng thử lại sau!",
            DelayToastTime
          );
          navigate(-1);
        }
        setLoading(false);
      });
    }
  }, [state, userData, orderInfo, setFormSubmitOrder]);

  //Lấy dữ liệu location
  const ConvertOptions = (list) =>
    list.map((item) => ({ value: item.code, label: item.name }));

  const getDataLocation = async (type) => {
    const keyAddress = {
      1: "province",
      2: "district",
      3: "ward",
      4: "hamlet",
    };
    if (type > 1) {
      if (!formSubmitOrder.location[keyAddress[type - 1]]) {
        return false;
      }
    }
    if (optionAddress[keyAddress[type]]?.length) {
      return;
    }
    const result = await CallApiBackend(
      {
        type: type,
        parent_code: formSubmitOrder.location[keyAddress[type - 1]]?.code,
      },
      GetAddress
    );
    if (result.data.status === 1) {
      const convert = ConvertOptions(result.data.data);
      setOptionAddress((draft) => {
        draft[keyAddress[type]] = convert;
      });
    }
  };
  const handleChangeLocation = async (e, type) => {
    const keyAddress = {
      1: "province",
      2: "district",
      3: "ward",
      4: "hamlet",
    };
    const locationSelect = optionAddress[keyAddress[type]].find(
      (item) => "" + item.value === "" + e
    );
    setOptionAddress((draft) => {
      for (let i = type + 1; i <= 4; i++) {
        draft[keyAddress[i]] = [];
      }
    });
    setFormSubmitOrder((draft) => {
      draft.location[keyAddress[type]] = {
        code: locationSelect.value,
        name: locationSelect.label,
      };
      for (let i = type + 1; i <= 4; i++) {
        draft.location[keyAddress[i]] = null;
      }
    });
    // if (type === 3 && !orderInfo.isHamlet) {
    //   const optionLv4 = await getLv4Address({
    //     location: {
    //       province: formSubmitOrder.location.province.name,
    //       district: formSubmitOrder.location.district.name,
    //       ward: locationSelect.label,
    //     },
    //   });
    //   if (Array.isArray(optionLv4) && optionLv4.length) {
    //     setOptionAddress((draft) => {
    //       draft.hamlet = optionLv4.map((item) => ({
    //         value: item,
    //         label: item,
    //       }));
    //     });
    //   }
    // }
  };

  const convertValueDefault = (item) => {
    if (!item) return null;
    return { value: item.code, label: item.name };
  };

  // ấn nút thanh toán
  const onClickPayment = async () => {
    setLoadingButtonBuyNow(true);
    if (
      !formSubmitOrder.location.province ||
      !formSubmitOrder.location.district ||
      !formSubmitOrder.location.ward ||
      !formSubmitOrder.location.address
    ) {
      Msg.notifyError(
        "Vui lòng điền đầy đủ thông tin giao hàng!",
        DelayToastTime
      );
      setLoadingButtonBuyNow(false);
    } else if (!formSubmitOrder.fullname || !formSubmitOrder.phone) {
      Msg.notifyError(
        "Vui lòng điền đầy đủ thông tin người nhận!",
        DelayToastTime
      );
      setLoadingButtonBuyNow(false);
    } else if (ValidatePhone(formSubmitOrder.phone).status === false) {
      Msg.notifyError("Vui lòng điền số điện thoại chính xác!", DelayToastTime);
      setLoadingButtonBuyNow(false);
      // } else if (!formSubmitOrder.polixy_check) {
      //   Msg.notifyError("Bạn chưa đồng ý với điều khoản dịch vụ!", DelayToastTime);
    } else {
      if (!executeRecaptcha) {
        console.log("Execute recaptcha not yet available");
        setLoadingButtonBuyNow(false);
        return;
      }
      let recaptchaValue = await executeRecaptcha("create_order");
      CallApiBackend(
        { ...formSubmitOrder, recaptcha: recaptchaValue },
        TaoDonHang,
        "POST"
      ).then((result) => {
        if (result.data.status === 1) {
          if (userData?.is_loggedIn) {
            let userInfoNew = userData;
            if (state.isCart === true) {
              let { cart_count } = userData;
              if (cart_count) {
                state.classify.forEach((item) => {
                  if (cart_count[item.supplier_id])
                    cart_count[item.supplier_id] -= 1;
                });
                userInfoNew.cart_count = cart_count;
              }
            }
            userInfoNew.address = {
              ...(userInfoNew?.address || {}),
              metadata: {
                ...formSubmitOrder.location,
                fullname: formSubmitOrder.fullname,
                phone: formSubmitOrder.phone,
                note: formSubmitOrder.note,
                shipment_mode: userData?.current_ship_mode,
              },
            };
            updateUserInfo({ ...userInfoNew });
          }

          if (!userData?.is_loggedIn && cartData) {
            if (state.isCart) {
              const cartDataTemp = cartData?.filter(
                (cartDataItem) =>
                  !Object.keys(formSubmitOrder?.classifies)
                    ?.map(Number)
                    ?.includes(cartDataItem?.classify_id)
              );
              updateCartInfo(cartDataTemp);
            }
          }

          if (result?.data?.data?.payment_url) {
            window.location.href = result?.data?.data?.payment_url;
          } else {
            navigate(`/order/result?orderId=${result?.data?.data?.order_id}`);
          }
        } else {
          Msg.notifyError(result.data.message || "", DelayToastTime);
        }
        setLoadingButtonBuyNow(false);
      });
    }
  };

  //chọn phương thức thanh toán
  const onChangePaymentType = async (e) => {
    const payment_current = orderInfo.payment_type.find(
      (item) => item.value === e.target.value
    );
    if (payment_current.card_list) {
      //lấy danh sách thẻ
      if (!listCard) {
        setLoadingListCard(true);
        const { data } = await getListCardRequest({
          payment_type: payment_current.value,
        }).then((res) => {
          setLoadingListCard(false);
          return res;
        });
        if (data.status === 1 && data.data?.length > 0) {
          setListCard(
            data.data.map((item) => ({
              id: item.card_id,
              cardNumber: item.card_number,
            }))
          );
        } else {
          setListCard([]);
          Msg.notifyError(
            "Quý khách chưa có thẻ. Vui lòng đăng ký để sử dụng ưu đãi này hoặc chọn phương thức thanh toán khác!"
          );
          return;
        }
      } else if (listCard.length === 0) {
        Msg.notifyError(
          "Quý khách chưa có thẻ. Vui lòng đăng ký để sử dụng ưu đãi này hoặc chọn phương thức thanh toán khác!"
        );
        return;
      }
    }
    setFormSubmitOrder((draft) => {
      draft.payment_type = e.target.value;
      draft.display_price = payment_current?.payAmount;
    });
  };
  const changeCardId = (e) => {
    setFormSubmitOrder((draft) => {
      draft.card_id = e.target.value;
    });
  };

  const handleFocus = (inputRef, block = "center") => {
    // setTimeout(() => {
    //   if (inputRef.current) {
    //     inputRef.current.scrollIntoView({
    //       behavior: "smooth",
    //       block: block,
    //       inline: "nearest",
    //     });
    //   }
    // }, 10); // Đợi bàn phím xuất hiện
  };
  return (
    <div className="flex flex-col">
      <TopBar goHome={false} title={"Thanh toán"} />
      <LoadingCustom isLoading={loadingListCard} isFullScreen={false} />
      {!loading && orderInfo ? (
        <>
          <Space direction="vertical" size={15} className="p-3.5 bg-[#FCF4DE]">
            <Space direction="vertical" size={10}>
              <Typography.Text className="font-semibold text-base uppercase mb-1 block">
                Thông tin giỏ hàng
              </Typography.Text>
              <div className="grid grid-cols-1 gap-5">
                {orderInfo.data?.[0]?.classifies.map((orderInfoItem, key) => (
                  <ItemProductList
                    item={{
                      ...orderInfoItem,
                      qty: formSubmitOrder.classifies[
                        orderInfoItem.classify_id
                      ],
                    }}
                    key={key}
                    mode={2}
                    numberIndex={key + 1}
                  />
                ))}
              </div>
              <Space
                direction="vertical"
                size={7}
                className="whitespace-nowrap px-2 py-3 bg-[#FFFDF7] rounded-lg text-sm mt-2"
              >
                <Row justify="space-between" align="top">
                  <Col>Tạm tính</Col>
                  <Col className="whitespace-nowrap text-base font-semibold">
                    {formatPrice(orderInfo.data?.[0]?.sale_price)} đ
                  </Col>
                </Row>
                <Row justify="space-between" align="top">
                  <Col>Phí giao hàng</Col>
                  <Col className="whitespace-nowrap text-base font-semibold">
                    {formatPrice(orderInfo.data?.[0]?.transport_fee)} đ
                  </Col>
                </Row>
                {orderInfo.data?.[0]?.transport_fee_discount &&
                orderInfo.data?.[0]?.transport_fee_discount > 0 ? (
                  <Row justify="space-between" align="top">
                    <Col>Miễn phí giao hàng</Col>
                    <Col className="whitespace-nowrap text-base font-semibold">
                      -
                      {formatPrice(orderInfo.data?.[0]?.transport_fee_discount)}{" "}
                      đ
                    </Col>
                  </Row>
                ) : (
                  ""
                )}

                <Row justify="space-between" align="top">
                  <Col>Tổng tiền thanh toán</Col>
                  <Col className="whitespace-nowrap text-base font-semibold">
                    {formatPrice(
                      orderInfo.data?.[0]?.sale_price +
                        orderInfo.data?.[0]?.transport_fee -
                        orderInfo.data?.[0]?.transport_fee_discount -
                        orderInfo.payment_type.find(
                          (e) => e.value === formSubmitOrder.payment_type
                        )?.pay_discount || 0
                    )}{" "}
                    đ
                  </Col>
                </Row>
                {orderInfo.data?.[0]?.payment_needed_to_freeship &&
                orderInfo.data?.[0]?.payment_needed_to_freeship > 0 ? (
                  <Row justify="end">
                    <Col
                      xs={24}
                      className="whitespace-normal text-[#E72B3D] text-right text-base font-semibold"
                    >
                      (Mua thêm{" "}
                      {formatPrice(
                        orderInfo.data?.[0]?.payment_needed_to_freeship
                      )}{" "}
                      đ để miễn phí giao hàng)
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </Space>
            </Space>
            <Space direction="vertical" size={10}>
              <Typography.Text className="font-semibold text-base uppercase mb-1 block">
                Thông tin người nhận
              </Typography.Text>
              <Row
                ref={inputNameRef}
                className="relative px-3 py-2.5 bg-[#FFFDF7] border border-[#FFE8AA] rounded"
                align="middle"
                wrap={false}
              >
                <Col span={23}>
                  <Text className="text-sm text-[#C1A047]">Tên người nhận</Text>
                  <Input
                    bordered={false}
                    onChange={(e) =>
                      setFormSubmitOrder((draft) => {
                        draft.fullname = e.target.value;
                      })
                    }
                    onFocus={() => handleFocus(inputNameRef)}
                    rootClassName="px-0 z-10"
                    value={formSubmitOrder.fullname}
                  />
                </Col>
                <Col
                  onClick={() =>
                    setFormSubmitOrder((draft) => {
                      draft.fullname = "";
                    })
                  }
                  span={1}
                  className="flex justify-center opacity-80"
                >
                  <img src={close} width={19} height={19} alt="" />
                </Col>
              </Row>
              <Row
                ref={inputPhoneRef}
                className="relative px-3 py-2.5 bg-[#FFFDF7] border border-[#FFE8AA] rounded"
                align="middle"
                wrap={false}
              >
                <Col span={23}>
                  <Text className="text-sm text-[#C1A047]">Số điện thoại</Text>
                  <Input
                    bordered={false}
                    onChange={(e) =>
                      setFormSubmitOrder((draft) => {
                        draft.phone = (e.target.value || "").replace(/\D/g, "");
                      })
                    }
                    onFocus={() => handleFocus(inputPhoneRef)}
                    rootClassName="px-0 z-10"
                    value={formSubmitOrder.phone}
                  />
                </Col>
                <Col
                  onClick={() =>
                    setFormSubmitOrder((draft) => {
                      draft.phone = "";
                    })
                  }
                  span={1}
                  className="flex justify-center opacity-80"
                >
                  <img src={close} width={19} height={19} alt="" />
                </Col>
              </Row>
            </Space>
            <Space direction="vertical" size={10} className="custom-select1">
              <Typography.Text className="font-semibold text-base uppercase mb-1 block">
                Thông tin giao hàng
              </Typography.Text>
              {listLocationRender.map(
                (item, key) =>
                  (!item.check || orderInfo[item.check]) && (
                    <Row
                      ref={item?.ref}
                      className="relative px-3 py-2.5 bg-[#FFFDF7] border border-[#FFE8AA] rounded"
                      align="middle"
                      wrap={false}
                      key={key}
                    >
                      <Col span={23}>
                        <Text className="text-sm text-[#C1A047]">
                          {item.name}
                        </Text>
                        <Select
                          notFoundContent={
                            <span className="flex justify-center py-2">
                              <Spin size="small" />
                            </span>
                          }
                          bordered={false}
                          suffixIcon={<></>}
                          className="w-full z-10"
                          showSearch
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          value={convertValueDefault(
                            formSubmitOrder.location[item.key]
                          )}
                          onFocus={() => {
                            getDataLocation(item.type);
                            handleFocus(item?.ref);
                          }}
                          onChange={(e) => handleChangeLocation(e, item.type)}
                          placeholder={item.placeholder}
                          options={optionAddress[item.key]}
                        />
                      </Col>
                      <Col span={1} className="flex justify-center opacity-80">
                        <img src={arrowDown} width={12} alt="" />
                      </Col>
                    </Row>
                  )
              )}
              <Row
                ref={inputAddressRef}
                className="relative px-3 py-2.5 bg-[#FFFDF7] border border-[#FFE8AA] rounded"
                align="middle"
                wrap={false}
              >
                <Col span={23}>
                  <Text className="text-sm text-[#C1A047]">
                    Đường, Số nhà, toà nhà
                  </Text>
                  <Input
                    bordered={false}
                    rootClassName="px-0 z-10"
                    placeholder="Nhập địa chỉ"
                    onChange={(e) =>
                      setFormSubmitOrder((draft) => {
                        draft.location = {
                          ...(draft.location || {}),
                          address: e.target.value,
                        };
                      })
                    }
                    onFocus={() => handleFocus(inputAddressRef)}
                    value={formSubmitOrder.location?.address || ""}
                  />
                </Col>
              </Row>
              <Row
                ref={inputNoteRef}
                className="relative px-3 py-2.5 bg-[#FFFDF7] border border-[#FFE8AA] rounded"
                align="middle"
                wrap={false}
              >
                <Col span={23}>
                  <Text className="text-sm text-[#C1A047]">Ghi chú</Text>
                  <Input
                    bordered={false}
                    rootClassName="px-0 z-10"
                    placeholder="Thêm ghi chú"
                    onChange={(e) =>
                      setFormSubmitOrder((draft) => {
                        draft.note = e.target.value;
                      })
                    }
                    onFocus={() => handleFocus(inputAddressRef)}
                    value={formSubmitOrder.note}
                  />
                </Col>
              </Row>
            </Space>
            <Space direction="vertical" size={10} className="custom-select1">
              <Typography.Text className="font-semibold text-base uppercase mb-1 block">
                <span className="block leading-3">PHƯƠNG THỨC THANH TOÁN</span>
                {/* <span className="text-10 font-light normal-case">Bảo đảm thanh toán bởi Ngân hàng Quân đội -
                    MBBank</span> */}
              </Typography.Text>
              <Radio.Group
                className="custom-radio-group w-full"
                onChange={onChangePaymentType}
                value={formSubmitOrder.payment_type}
              >
                <Space direction="vertical">
                  {paymentType.map((item, key) => (
                    <div
                      className="px-3 py-2.5 border border-[#FFE8AA] rounded"
                      key={key}
                    >
                      {item.groups ? (
                        <>
                          <div className="w-10/12">
                            <Text className="block text-[#743E02] uppercase font-semibold">
                              {item?.title}
                            </Text>
                            <Text className="text-11 block font-light">
                              {item?.description}
                            </Text>
                          </div>
                          {item.groups && (
                            <Space
                              direction="vertical"
                              className="py-1"
                              size={10}
                              split={
                                <span className="block h-1 border-b w-full border-gray-200" />
                              }
                            >
                              {item.groups.map((_i, _k) => (
                                <div key={_k}>
                                  <Radio value={_i.value} className="py-2">
                                    <span
                                      className="block"
                                      dangerouslySetInnerHTML={{
                                        __html: _i.metadata?.title,
                                      }}
                                    />
                                    <Text className="text-11 font-light">
                                      {_i.metadata?.description}
                                    </Text>
                                  </Radio>
                                  {_i.card_list &&
                                    formSubmitOrder.payment_type ===
                                      _i.value && (
                                      <>
                                        <LoadingCustom
                                          isLoading={false}
                                          isFullScreen={false}
                                        />
                                        <Radio.Group
                                          name="card"
                                          onChange={changeCardId}
                                          value={formSubmitOrder.card_id}
                                          className="custom-radio-group w-full"
                                        >
                                          <Space
                                            direction="vertical border-t border-gray-200 pt-3"
                                            size={10}
                                            split={
                                              <span className="block h-1 border-b w-full border-gray-200" />
                                            }
                                          >
                                            {listCard?.length > 0 &&
                                              listCard.map((__i, __k) => (
                                                <Radio
                                                  value={__i.id}
                                                  className="pl-5"
                                                  key={__k}
                                                >
                                                  <Text>{__i.cardNumber}</Text>
                                                </Radio>
                                              ))}
                                          </Space>
                                        </Radio.Group>
                                      </>
                                    )}
                                </div>
                              ))}
                            </Space>
                          )}
                        </>
                      ) : (
                        <Radio value={item.value}>
                          <div className="flex flex-row justify-start items-center gap-5">
                            <img
                              src={`/images/catviet/${item?.icon}.png`}
                              alt=""
                              className="w-7 h-auto object-contain"
                            />
                            <Text className="block text-[#743E02] uppercase font-semibold">
                              {item?.title}
                            </Text>
                          </div>

                          {/* <Text className="text-11 block font-light">
                            {item?.description}
                          </Text> */}
                          {/* {
                                    !!item.show_payment && formSubmitOrder.payment_type === item.value &&
                                    <Row justify="space-between" className="font-semibold" align="middle">
                                      <Text className="text-12">Số tiền khách hàng phải đặt cọc</Text>
                                      <Text className="text-red text-12">{formatPrice(item.payAmount)} đ</Text>
                                    </Row>
                                  } */}
                        </Radio>
                      )}
                    </div>
                  ))}
                </Space>
              </Radio.Group>
            </Space>
            {/* <Space direction="vertical" size={10}>
                <Typography.Text className="font-semibold text-base uppercase mb-1 block">
                  ĐIỀU KHOẢN DỊCH VỤ & CHÍNH SÁCH BẢO HÀNH
                </Typography.Text>
                <div className="px-3 py-2.5 border border-gray-200 rounded">
                  <Checkbox
                    className="custom-checkbox"
                    onChange={e => setFormSubmitOrder(draft => {
                      draft.polixy_check = e.target.checked
                    })}>
                    <Text className="text-sm text-blue-700">Tôi đã đọc và đồng ý với <span
                      className="font-semibold underline" onClick={() => setOpenDieuKhoan(!openDieuKhoan)}>Điều khoản
                      Dịch
                      vụ & Chính sách Bảo hành</span></Text>
                  </Checkbox>
                </div>
              </Space> */}
            {/* <hr className="w-9/12 mx-auto"/>
              <Text className="text-center text-blue-700 block text-12">
                Nếu Quý khách có nhu cầu nhận Hóa đơn điện tử, vui lòng liên hệ <span
                className="text-red">Hotline</span>
                <a href="tel:1900636819" className="font-semibold inline text-red">&nbsp;1900 63 68 19</a> trước khi
                nhận
                hàng thành công.
              </Text> */}
          </Space>
          <BottomPurchase paddingX={15} paddingY={20}>
            <div className={"d-flex justify-content-between"}>
              <div className={"total-money"} style={{ margin: "auto 0" }}>
                <p className="text-xl text-[#E5454D] lowercase">
                  {formatPrice(
                    orderInfo.payment_type.find(
                      (e) => e.value === formSubmitOrder.payment_type
                    )?.payAmount ?? 0
                  )}
                  {" đ"}
                </p>
              </div>
              <div className={"buy-now"}>
                <Button
                  loading={loadingButtonBuyNow}
                  onClick={onClickPayment}
                  type="primary"
                  className="w-full button py-2 h-12"
                >
                  Đặt hàng
                </Button>
              </div>
            </div>
          </BottomPurchase>
          {/* {
              orderInfo.data?.[0]?.supplier_id === 2 ?
                <DieuKhoanDichVuDHC openDieuKhoan={openDieuKhoan}/> :
                <DieuKhoanDichVu openDieuKhoan={openDieuKhoan}/>
            } */}
        </>
      ) : (
        <Skeleton paragraph={{ rows: 10 }} active className="p-3" />
      )}
    </div>
  );
}
