import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import home from "./../../assets/images/home.png";
import { useContext } from "react";
import { UserAuth } from "../../context/AuthContext";

export const TopBar = ({
  isBack = true,
  title = "",
  goCart = false,
  goOption = false,
  goHome = true,
  onClose = null,
  supplier = null,
}) => {
  let navigate = useNavigate();
  const { userData, cartData } = useContext(UserAuth);
  const pathName = window.location.pathname;
  const handleBack = () => {
    if (onClose !== null) {
      onClose();
    } else {
      if (pathName?.includes("/order/result")) {
        navigate("/");
      } else if (pathName !== "/" && pathName !== "/mbapp") navigate(-1);
    }
  };
  return (
    <header>
      <nav>
        <div className={"nav-item"}>
          {isBack && pathName !== "/" && pathName !== "/mbapp" ? (
            <p onClick={handleBack}>
              <img
                src="/images/catviet/btn_back.png"
                alt=""
                className="w-6 h-6"
              />
            </p>
          ) : (
            ""
          )}
        </div>
        <div className={"nav-item"}>
          <p className={"page-name"}>{title}</p>
        </div>
        <div className={"nav-item"} style={{ alignItems: "end" }}>
          {goCart ? (
            <Link
              to={"/gio-hang"}
              style={{ position: "relative" }}
              state={{
                supplier_id: supplier,
              }}
            >
              <img
                src="/images/catviet/i_shoping.png"
                alt=""
                className="object-contain w-6 h-6"
              />
              {userData?.cart_count?.[supplier] &&
              userData.cart_count[supplier] > 0 ? (
                <span style={styles.badgeNoti}>
                  {userData?.cart_count[supplier]}
                </span>
              ) : (
                ""
              )}
              {!userData?.is_loggedIn && cartData?.length > 0 ? (
                <span style={styles.badgeNoti}>{cartData?.length}</span>
              ) : (
                ""
              )}
            </Link>
          ) : (
            ""
          )}
          {userData?.is_loggedIn && goOption ? (
            <Link to={"/history"}>
              <img
                src="/images/catviet/option.png"
                alt=""
                className="object-contain w-5 h-6"
              />
            </Link>
          ) : (
            ""
          )}
          {goHome && pathName !== "/" && pathName !== "/mbapp" ? (
            <Link to={"/"}>
              <img src={home} alt="" className="object-contain w-6 h-6" />
            </Link>
          ) : (
            ""
          )}
        </div>
      </nav>
    </header>
  );
};

const styles = {
  badgeNoti: {
    position: "absolute",
    top: "-20%",
    right: "-20%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "14px",
    height: "14px",
    backgroundColor: "#FF0000",
    color: "white",
    fontSize: "12px",
    lineHeight: "21px",
    borderRadius: "50%",
  },
};
