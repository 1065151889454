import React from 'react';
import {Link} from "react-router-dom";

const NotFound = () => {
  return (
    <>
      <div className="w-screen max-h-screen h-screen fixed top-0 left-0 z-10">
        <img
          src={'/images/background_not_found.jpg'}
          className="w-full h-full object-cover"
          alt=""
        />
        <Link
          className="py-2 px-5 bg-[#290e4d] fixed bottom-3 rounded-full transform left-1/2 -translate-x-1/2 whitespace-nowrap text-white shadow"
          to="/"
        >
          Quay lại trang chủ
        </Link>
      </div>
    </>
  );
};
export default NotFound;
